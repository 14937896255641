<template>
    <a-form-model
      ref="linkEditForm"
      :model="form"
      :rules="rules"
    >
      <div class="width-100p text-center">
        <a-upload
          name="avatar"
          list-type="picture-card"
          action="#"
          :class="imageRequirements.proportions.ratio > 1 ? 'link-cover-uploader-2' : 'link-cover-uploader-1'"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          @change="imgChange"
        >
          <img v-if="form.img_path" :src="form.img_path" alt="link image" class="img_link_cover" />
          <div v-else>
            <a-icon :type="imgLoading ? 'loading' : 'inbox'" class="text-gray-6 font-size-32"/>
            <div class="ant-upload-text text-gray-6 font-weight-lighter font-size-14">
              Image size
              <span class="font-size-12 d-block">
                {{ imageRequirements.min_width }} x {{ imageRequirements.min_height }} px<br/>
              ( .jpg or .png )
              </span>
            </div>
          </div>
        </a-upload>
      </div>
      <a-tabs>
        <a-tab-pane v-for="language in this.langs" :key="language.id" :tab="language.desc">
          <a-form-model-item label="Name" :prop="`localizations.${form.localizations.findIndex(localization => localization.lang_id == language.id).toString()}.title`" :rules="[{ required: true, message: `This field is required`, trigger: 'change', },]">
            <a-input @change="(e) => onLocalizationChange(form.localizations.findIndex(localization => localization.lang_id == language.id), e.target.value)" :value="form.localizations.find(localization => localization.lang_id == language.id)?.title" :placeholder="`${language.desc} name`"/>
          </a-form-model-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-model-item
        ref="link"
        label="Link"
        prop="link"
      >
        <a-input-group compact class="d-flex">
          <a-select v-model="form.link_type">
            <a-select-option value="webview">
              WebView
            </a-select-option>
            <a-select-option value="deeplink">
              Deeplink
            </a-select-option>
          </a-select>
          <a-input
            v-model="form.link"
            class="ml-auto"
          />
        </a-input-group>
      </a-form-model-item>
      <a-form-model-item ref="is_active" prop="is_active">
        <a-switch v-model="form.is_active" />
        <span class="ml-2">Published</span>
      </a-form-model-item>
      <hr>
      <a-row>
        <a-col class="text-left" :span="6">
          <span v-if="linkObj">
            <a-popconfirm
              title="Sure to delete?"
              @confirm="deleteRecord"
            >
              <a href="javascript:;">
                <a-button type="danger" :loading="deleteLoading">Delete</a-button>
              </a>
            </a-popconfirm>
          </span>
        </a-col>
        <a-col class="text-right" :span="18">
          <a-button @click="closeModal">
            Cancel
          </a-button>
          <a-button v-if="linkObj" :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Save
          </a-button>
          <a-button v-else :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Create
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
</template>
<script>
import TopService from '@/services/api/apiTopService'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'linkModal',
  props: ['linkObj', 'sectionId', 'sectionType', 'langs'],
  components: {},
  data() {
    const initialLocalizations = this.langs.map(lang => ({
      lang_id: lang.id,
      title: this.linkObj ? this.linkObj.localizations.find(el => el.lang_id === lang.id)?.title || '' : '',
    }))
    return {
      submitLoading: false,
      deleteLoading: false,
      imgLoading: false,
      imageUrl: this.linkObj ? this.linkObj.img_path : '',
      updateCover: false,
      imageRequirements: {
        proportions: { x: this.sectionType.prop_x, y: this.sectionType.prop_y, ratio: Math.round(10 * (this.sectionType.prop_x / this.sectionType.prop_y)) / 10 },
        min_width: this.sectionType.img_min_x,
        min_height: this.sectionType.img_min_y,
      },
      form: {
        link: this.linkObj ? this.linkObj.link : '',
        link_type: this.linkObj ? this.linkObj.link_type : 'webview',
        weight: this.linkObj ? this.linkObj.weight : 0,
        is_active: this.linkObj ? this.linkObj.is_active : false,
        img_path: this.linkObj ? this.linkObj.img_path : '',
        imgFile: undefined,
        localizations: initialLocalizations,
      },
      rules: {
        link: [],
      },
    }
  },
  watch: {
    linkIsFilled(val) {
      if (val) {
        this.rules.link = [{ required: true, validator: this.validateLink, trigger: 'change' }]
      } else {
        this.$refs.linkEditForm.clearValidate()
        this.rules.link = [{ required: false, validator: this.validateLink, trigger: 'change' }]
      }
    },
  },
  computed: {
    linkIsFilled() {
      return this.form.link.length
    },
  },
  methods: {
    onLocalizationChange(id, title) {
      this.form.localizations[id].title = title
    },
    validateLink(rule, value, callback) {
      if (this.form.link_type === 'deeplink') {
        if (this.form.link_type.length) {
          callback()
        } else {
          callback(new Error('Link is required'))
        }
        return
      }
      if (value.length && rule.required) {
        /* eslint-disable no-useless-escape */
        const RegExpLnk = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/
        if (RegExpLnk.test(value)) {
        } else {
          callback(new Error('Wrong link'))
        }
      } else {
        callback(new Error('Link is required'))
      }
      callback()
    },
    imgChange(info) {
      if (info.file.status === 'uploading') {
        this.imgLoading = true
        return
      }
      this.form.imgFile = info.file.originFileObj
      getBase64(info.file.originFileObj, imageUrl => {
        this.imageUrl = imageUrl
        this.form.img_path = imageUrl
        this.imgLoading = false
      })
      this.updateCover = true
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG or PNG file!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('Image must smaller than 5MB!')
        return false
      }
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = (err) => {
          const inProportions = Math.round(10 * (img.width / img.height)) / 10 === this.imageRequirements.proportions.ratio
          if (!inProportions) {
            this.$message.error(`Image must be in correct proportions - ${this.imageRequirements.proportions.x} : ${this.imageRequirements.proportions.y} (e.g. 300 x 300 px)`)
            reject(err)
          } else {
            resolve()
          }
        }
      })
    },
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitCreateModal(createdRecord) {
      this.$emit('createLink', createdRecord)
    },
    submitEditModal(updatedRecord) {
      this.$emit('updateLink', updatedRecord)
    },
    submitDeleteModal(deletedId) {
      this.$emit('deleteLink', deletedId)
    },
    onSubmit() {
      this.$refs.linkEditForm.validate(valid => {
        if (valid) {
          if (this.linkObj) {
            this.updateRecord()
          } else {
            this.createRecord()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async createRecord() {
      if (!this.form.imgFile) {
        this.$message.error('Upload image to create new link')
        return false
      }
      this.submitLoading = true
      return TopService.createRecord(this.sectionId, this.form).then((response) => {
        this.submitLoading = false
        const created = response.data.data
        this.$notification.success({
          message: 'New link created',
          description: '',
        })
        this.putCover(created)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating link',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async updateRecord() {
      this.submitLoading = true
      return TopService.updateRecord(this.sectionId, this.linkObj.id, this.form).then((response) => {
        this.submitLoading = false
        const updated = response.data.data
        this.$notification.success({
          message: 'Link updated',
          description: '',
        })
        if (this.updateCover) { this.putCover(updated, true) } else { this.submitEditModal(updated) }
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error while updating link',
          description: error.message,
        })
      })
    },
    async deleteRecord() {
      this.deleteLoading = true
      return TopService.deleteRecord(this.sectionId, this.linkObj.id).then((response) => {
        this.$notification.success({
          message: 'Link successfully deleted',
          description: '',
        })
        this.deleteLoading = false
        this.submitDeleteModal(this.linkObj.id)
      }).catch(error => {
        console.log(error)
        this.deleteLoading = false
        this.$notification.error({
          message: 'Error while deleting link',
          description: error.message,
        })
      })
    },
    putCover(record, onEdit = false) {
      return TopService.recordPutCover(this.sectionId, record.id, this.form.imgFile).then((response) => {
        const uploaded = response.data.data
        this.$notification.success({
          message: 'Link cover uploaded',
          description: '',
        })
        this.submitLoading = false
        if (onEdit) { this.submitEditModal(uploaded) } else { this.submitCreateModal(uploaded) }
      }).catch(error => {
        this.$notification.error({
          message: 'Error while uploading link cover',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
  },
  mounted() {
    if (this?.linkObj?.link?.length) {
      this.rules.link = [{ required: true, validator: this.validateLink, trigger: 'change' }]
    }
  },
}
</script>
<style scoped>
.ant-form-item-label {
    padding: 0px !important;
}
.link-image {
  margin-bottom: 20px;
  display: inline-block;
}
</style>
